import React from "react";
import { Link } from "react-router-dom";
// import botIcon from "./bot-icon.png"; 
import bot from "./Bot.png";
import AuthService from "./authService";
export default function DashNavbar() {
  const handleLogout = () => {
    // Call the logout method from AuthService
    AuthService.logout()
      .then(() => {
        // Redirect to the sign-in page after successful logout
        window.location.replace("/sign-in");
      })
      .catch((error) => {
        console.error("Logout Error:", error);
        // Handle logout error if needed
      });
  };

  return (
    <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
      <div className="container">
        <div className="d-flex align-items-center">
          <img src={bot} alt="Bot Icon" className="navbar-icon me-1" style={{ width: '4vw', height: '4vw', margin: '-4%' }} />
          <Link className="navbar-brand ms-auto" to="/">
            BaapGPT
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/sign-in" onClick={handleLogout}>
                Log out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

import React, { useState } from 'react';
import axios from 'axios';
import Navbar from "./Navbar";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import AuthService from './authService';


export default function Login() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = (event) => {
    event.preventDefault();

    const loginData = {
      email: formData.email,
      password: formData.password,
    };

    AuthService.login(formData.email, formData.password);

    axios.post('http://3.110.29.90:8002/Login_view/', formData, { timeout: 100000 })
      .then(response => {
        console.log('Login Successful:', response.data);

        Swal.fire({
          icon: 'success',
          title: 'Login Successful!',
          text: 'Welcome!',
        });

        // Redirect to the home page after successful login
        navigate('/dashboard');
      })
      .catch(error => {
        console.error('Login Error:', error.response);

        Swal.fire({
          icon: 'error',
          title: 'Login Error',
          text: 'Invalid email or password. Please try again.',
        });
      });

      
  };
  const handleGoogleLogin = () => {
    // Make a request to the Google login API
    axios.get('http://3.110.29.90:8002/accounts/google/login/', { timeout: 100000 })
      .then(response => {
        // Handle the response if needed
      })
      .catch(error => {
        console.error('Google Login Error:', error);
      });
  };

  return ( 
    <>
      <Navbar />
      <section className="background-radial-gradient overflow-hidden" style={{ marginTop: '-80px' }}>
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
              <h1 className="my-4 display-3 fw-bold ls-tight" style={{ color: 'hsl(218, 81%, 95%)', marginLeft: '0' }}>
              Chat Brilliance<br />
                <span style={{ color: 'hsl(218, 81%, 75%)' }}>Starts Here-Sign In Now!</span>
              </h1>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
              <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

              <div className="auth-inner pb-3 pt-4 mt-0 bg-glass ms-5 text-center">
                <form onSubmit={handleLogin} >
                  <h3 className="mb-2">Sign In</h3>
                  <div className="mb-3 d-flex flex-column align-items-start">
                    <label>Email address</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3 d-flex flex-column align-items-start">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Enter password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3 d-flex flex-column align-items-start">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                      />
                      <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                    </div>
                  </div>

                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>

                  <p className="forgot-password text-right">Forgot <a href="/forgotPass">password?</a></p>
                  <br />
                  <div class="text-center">
                    <p>or sign in with:</p>
                    <button type="button" class="btn btn-link btn-floating mx-1" onClick={() => handleGoogleLogin()}>
                      <i class="fab fa-google"></i>
                    </button>

                    <button type="button" class="btn btn-link btn-floating mx-1 ">
                      <i class="fab fa-facebook-f"></i>
                    </button>

                    <button type="button" class="btn btn-link btn-floating mx-1">
                      <i class="fa-brands fa-linkedin"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
} 
import React, { Component } from "react";
import "./animate.css";

export default class Home extends Component {
  render() {
    return (
      <section className="background-radial-gradient overflow-hidden" style={{ marginTop: '-80px' }}>
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-12 mx-auto mb-5 mb-lg-0 position-relative">
              <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
              <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
              <div className="content">
                  <h1 className="animation-h1 shine" style={{ color: 'hsl(218, 81%, 95%)', marginLeft: '0' }}>
                  Welcome to BaapGPT</h1>
                <h4 className="animation-h4 lead">Made by The DataTech Labs...</h4> <br />
                <a className="btn btn-outline-light mt-2" href="/sign-up">Start Here!</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import "./dash.css";
import AuthService from "./authService";
import InputSvg from "./send_icon.svg";
import DashNavbar from "./DashNavbar";
import Sidebar from "./Sidebar";
// import HashLoader from "react-spinners/HashLoader";
import FadeLoader from "react-spinners/FadeLoader";
import PulseLoader from "react-spinners/PulseLoader"
import { BiImageAdd } from "react-icons/bi";
import ClipboardJS from 'clipboard';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import DOMPurify from 'dompurify';
import bot from "./Bot.png";
// import MetroSpinner from "react-spinners-kit";

const SimpleMessage = ({ message, isBot }) => {
  const [isCopied, setIsCopied] = useState(false);

  const formattedMessage = DOMPurify.sanitize(
    typeof message === 'string'
      ? message.replace(/\*\*(.*?)\*\*/g, (_, match) => `<strong>${match}</strong>`)
      : ''
  );

  const isCodeResponse = typeof message === 'string' && message.includes('```');

  useEffect(() => {
    let clipboard;
    if (isBot && isCodeResponse) {
      clipboard = new ClipboardJS('.copy-button', {
        text: () => message,
      });

      clipboard.on('success', () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1500);
      });
    }

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, [isBot, isCodeResponse, message]);

  return (
    <div className={`message ${isBot ? 'bot' : 'user'}`}>
      <pre className={`message-box ${isBot ? 'bot-message-box' : 'user-message-box'}`}>
        <div className="message-content col-md-8">
          <div className={`message-text ${isCodeResponse ? 'code-block' : ''}`}>
            {isCodeResponse ? (
              <div className="code-response-box">
                <pre>
                  <code>{String(message)}</code>
                </pre>
                <button className="copy-button" data-clipboard-text={message}>
                  {isCopied ? 'Copied!' : 'Copy'}
                </button>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />
            )}
          </div>
        </div>
      </pre>
    </div>
  );
};

// TypingEffect as a separate functional component
const TypingEffect = ({ message, isBot, typingSpeed }) => {

  const [visibleChars, setVisibleChars] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  // const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    // const totalChars = message.reduce((total, message) => total + message.length, 0);
    const interval = setInterval(() => {
      setVisibleChars((prevVisibleChars) => {
        const nextVisibleChars = prevVisibleChars + 1;
        if (nextVisibleChars > message.length) {
          clearInterval(interval);
        }
        return nextVisibleChars;
      });
    }, typingSpeed || 20);

    return () => clearInterval(interval);

  }, [message, typingSpeed]);

  // const formattedMessage = DOMPurify.sanitize(message.replace(/\*\*(.*?)\*\*/g, (_, match) => `<strong>${match}</strong>`));
  const formattedMessage = DOMPurify.sanitize(
    typeof message === 'string'
      ? message.replace(/\*\*(.*?)\*\*/g, (_, match) => `<strong>${match}</strong>`)
      : ''
  );

  // const isCodeResponse = message.includes('```');
  const isCodeResponse =
    typeof message === 'string' && message.includes && message.includes('```');


  useEffect(() => {
    // Initialize clipboard.js for the copy button
    if (isBot && isCodeResponse) {
      const clipboard = new ClipboardJS('.copy-button', {
        text: function () {
          return message; // Set the entire message as the text to be copied
        },
      });

      // Handle success event
      clipboard.on('success', () => {
        setIsCopied(true);

        // Reset the copied state after a short delay
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      });

      // Dispose of the clipboard instance when the component unmounts
      return () => clipboard.destroy();
    }
  }, [isBot, isCodeResponse, message]);

  return (
    <div className={`message ${isBot ? 'bot' : 'user'}  `}>
      <pre className={`message-box ${isBot ? 'bot-message-box' : 'user-message-box'}`}>
        <div className="message-content ">
          <div className={`message-text ${isBot ? 'typing-effect' : ''}`}>
            <div className={`message-text ${isCodeResponse ? 'code-block ' : ''}`}>
              {isCodeResponse ? (
                <div className="code-response-box col-md-6">
                  <pre>
                    <code>{String(message)}</code>
                  </pre>
                  {/* Copy button */}
                  <button className="copy-button " data-clipboard-text={message}>
                    {isCopied ? 'Code Copied!' : 'Copy'}
                  </button>
                </div>
              ) : (
                // String(message).substring(0, visibleChars)
                // isBoldResponse ? <div>{visibleChars}</div> : String(message).substring(0, visibleChars)
                <div dangerouslySetInnerHTML={{ __html: formattedMessage.substring(0, visibleChars) }} />
              )}
              {isBot && <span className="cursor"></span>}
              {/* {message.substring(0, visibleChars)}
            {isBot && <span className="cursor"></span>} */}
            </div>
          </div>
        </div>
      </pre>
    </div>
  );
};


const Dashboard = () => {
  const [messages1, setMessages1] = useState([]);
  const [messages2, setMessages2] = useState([]);
  const [messages3, setMessages3] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isBotProcessing, setBotProcessing] = useState(false);
  // const [showDropdown, setShowDropdown] = useState(false);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [expandedCardContent, setExpandedCardContent] = useState(null);
  const [selectedApiResponse1, setSelectedApiResponse1] = useState(null);
  const [selectedApiResponse2, setSelectedApiResponse2] = useState(null);
  const [selectedApiResponse3, setSelectedApiResponse3] = useState(null);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);


  // // const handleExpandClick = (messages) => {
  //   setExpandedCardContent(messages);
  //   setShowModal(true);
  // };
  const handleExpandClick = (message, cardIndex) => {
    if (message === messages1) {
      setSelectedApiResponse1(message);
      setShowModal1(true);
    } else if (message === messages2) {
      setSelectedApiResponse2(message);
      setShowModal2(true);
    } else if (message === messages3) {
      setSelectedApiResponse3(message);
      setShowModal3(true);
    }
  };

  useEffect(() => {
    // Check if the user is authenticated
    if (!AuthService.isAuthenticated) {
      // Redirect to the login page
      navigate("/sign-in");
    }
  }, [navigate]);

  useEffect(() => {
    // Scroll to the end when messages change
    scrollToBottom();
  }, [messages1, messages2, messages3]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto', block: 'end' });
  };

  const autoExpand = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
    setUserInput(e.target.value);
  };

  const handleImageChange = (event) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      // Check file size, type, etc. if needed
      setSelectedImage(file);
    }
  };


  const sendMessageToDjango = async () => {
    if (isBotProcessing) {
      return;
    }

    setBotProcessing(true);

    const formData = new FormData();
    formData.append('user_input', userInput);
    if (selectedImage) {
      formData.append('img', selectedImage);
    }

    try {

      const response1 = await fetch(`http://3.110.29.90:8002/api1_chat/`, {
        method: "POST",
        body: formData,

      });

      if (!response1.ok) {
        throw new Error("Failed to fetch");
      }

      const responseData1 = await response1.json();
      console.log("API Response:", responseData1);

      setMessages1((prevMessages) => [...prevMessages, ...(Array.isArray(responseData1) ? responseData1 : [responseData1])]);
      setUserInput("");
    } finally {
      setLoading(true);
      setBotProcessing(false);
      scrollToBottom(false);
    }

    try {
      const response2 = await fetch(`http://3.110.29.90:8002/api2_chat/`, {
        method: "POST",
        body: JSON.stringify({ user_input: userInput }),
      });

      if (!response2.ok) {
        throw new Error("Failed to fetch");
      }

      const responseData2 = await response2.json();
      console.log("API Response2:", responseData2);

      const isCodeResponse = responseData2.some((message) => message[1] && message[1].includes("```"));
      setMessages2((prevMessages1) => [...prevMessages1, ...(Array.isArray(responseData2) ? responseData2 : [responseData2])]);

      setUserInput("");

      if (isCodeResponse) {
        scrollToBottom();
      }
    } finally {
      setLoading(false);
      setBotProcessing(false);
    }

    try {
      const response3 = await fetch(`http://3.110.29.90:8002/api3_chat/`, {
        method: "POST",
        body: JSON.stringify({ user_input: userInput }),
      });

      if (!response3.ok) {
        throw new Error("Failed to fetch");
      }

      const responseData3 = await response3.json();
      console.log("API Response3:", responseData3);

      const isCodeResponse = responseData3.some((message) => message[1] && message[1].includes("```"));
      setMessages3((prevMessages2) => [...prevMessages2, ...(Array.isArray(responseData3) ? responseData3 : [responseData3])]);
      setUserInput("");

      if (isCodeResponse) {
        scrollToBottom();
      }
    } finally {
      setLoading(false);
      setBotProcessing(false);
    }


  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // handleImageChange();
    sendMessageToDjango();
  };

  const handleImageClick = () => {
    if (!loading) {
      sendMessageToDjango();
    }
  };

  const handleCopy = (message) => {
    const clipboard = new ClipboardJS(`.copy-button-${message}`, {
      text: function () {
        return message; // Set the entire message as the text to be copied
      },
    });

    // Handle success event
    clipboard.on('success', () => {
      setIsCopied(true);

      // Reset the copied state after a short delay
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });

    // Dispose of the clipboard instance when the component unmounts
    clipboard.destroy();
  };
  return (
    <>
      <DashNavbar />
      <div className="d-flex">
        <Sidebar />
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-4 mb-3 mr-2">
              <div className="card">
                <div className="card-body">
                  <i
                    className="fa-solid fa-expand position-absolute top-0 end-0 me-2 mt-2"
                    onClick={() => handleExpandClick(messages1, 1)}
                    style={{ cursor: "pointer" }}
                  ></i>
                  <h5 className="card-title">Google Gemini</h5>
                  <div className="chat-container">
                    <div id="chat-messages">
                      {messages1.map((message, index) => (
                        <div key={index} className={`message ${message[1] ? 'bot' : 'user'}`}>
                          {message[1] ? (
                            <div className=" bot-message-box">
                              <div className="message-content" >
                                <div className="user-icon" style={{ display: 'flex', alignItems: 'center' }}>
                                  <img src={"https://st4.depositphotos.com/20523356/22445/v/450/depositphotos_224458102-stock-illustration-flat-user-icon-face-avatar.jpg"} alt="User" style={{ width: '40px', height: '40px' }} />
                                  <div className="text-right">User</div>
                                </div>
                                <div className="message-box message-image">
                                  {message[0]}
                                  {message[0] && message[0].type && message[0].type.startsWith && message[0].type.startsWith('image/')(
                                    <img
                                      src={URL.createObjectURL(message[0])}
                                      style={{ maxWidth: '100%', maxHeight: '150px', borderRadius: '8px' }}
                                    />
                                  )}
                                </div>
                                <div className="message-text">
                                  <div className="message-image" >
                                    <div className="bot-icon" style={{ display: 'flex', alignItems: 'center' }}>
                                      {/* <div className="text-left"><strong>Baap</strong></div> */}
                                      <img src={bot} alt="Bot Icon" className="navbar-icon img-fluid" style={{ width: '12%', height: '12%' }} />
                                      {/* <div className="text-right"><strong>GPT</strong></div> */}

                                    </div>
                                    <TypingEffect message={message[1]} isBot={true} typingSpeed={0} onCopy={handleCopy} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="message-content user-message-box">
                              <div className="message-box">
                                {message[0]}
                                {message[0] && message[0].type && message[0].type.startsWith && message[0].type.startsWith('image/') ? (
                                  <img
                                    src={URL.createObjectURL(message[0])}
                                    alt="User Uploaded Image"
                                    style={{ maxWidth: '100%', maxHeight: '150px', borderRadius: '8px' }}
                                  />
                                ) : (
                                  <div>{message[0]}</div>
                                )}
                              </div>
                              <div className="message-text">
                                {message[1]}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div ref={messagesEndRef} />
                      {loading && (
                        <div className="d-flex justify-content-start">
                          <PulseLoader
                            className="ms-3"
                            color="#177bdb"
                            size={10}
                            speedMultiplier={2}
                            css={{ position: 'absolute', bottom: '5px', right: '5px' }}
                          />
                        </div>
                      )}
                    </div>
                    <Modal show={showModal1} onHide={() => setShowModal1(false)} dialogClassName="modal-lg">
                      <Modal.Header closeButton>
                        <Modal.Title>Google Gemini</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {{ selectedApiResponse1 } &&
                          (
                            messages1.map((message, index) => (
                              <div key={index} className={`message ${message[1] ? 'bot' : 'user'}`}>
                                {message[1] ? (
                                  <div className=" bot-message-box ">
                                    <div className="message-content" >
                                      <div className="user-icon" style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={"https://st4.depositphotos.com/20523356/22445/v/450/depositphotos_224458102-stock-illustration-flat-user-icon-face-avatar.jpg"} alt="User" style={{ width: '40px', height: '40px' }} />
                                        <div className="text-right">User</div>
                                      </div>
                                      <div className="message-box1 message-image1" border-primary>
                                        {message[0]}
                                        {message[0] && message[0].type && message[0].type.startsWith && message[0].type.startsWith('image/')(
                                          <img
                                            src={URL.createObjectURL(message[0])}
                                            style={{ maxWidth: '100%', maxHeight: '150px', borderRadius: '8px' }}
                                          />
                                        )}
                                      </div>
                                      <div className="message-text">
                                        <div className="message-image1" >
                                          <div className="bot-icon" style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={bot} alt="Bot Icon1" className="navbar-icon img-fluid" style={{ width: '12%', height: '12%' }} />
                                          </div>
                                          <SimpleMessage message={message[1]} isBot={true} typingSpeed={0} onCopy={handleCopy} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="message-content user-message-box w-100">
                                    <div className="message-box col-md-8">
                                      {message[0]}
                                      {message[0] && message[0].type && message[0].type.startsWith && message[0].type.startsWith('image/') ? (
                                        <img
                                          src={URL.createObjectURL(message[0])}
                                          alt="User Uploaded Image"
                                          style={{ maxWidth: '100%', maxHeight: '150px', borderRadius: '8px' }}
                                        />
                                      ) : (
                                        <div>{message[0]}</div>
                                      )}
                                    </div>
                                    <div className="message-text col-md-4">
                                      {message[1]}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))
                          )
                        }
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3 mr-2">
              <div className="card">
                <div className="card-body">
                  <i
                    className="fa-solid fa-expand position-absolute top-0 end-0 me-2 mt-2"
                    onClick={() => handleExpandClick(messages2, 1)}
                    style={{ cursor: "pointer" }}
                  ></i>
                  <h5 className="card-title">HugChat</h5>
                  <div className="chat-container">
                    <div id="chat-messages">
                      {messages2.map((message, index) => (
                        <div key={index} className={`message ${message[1] ? 'bot' : 'user'}`}>
                          {message[1] ? (
                            <div className=" bot-message-box">
                              <div className="message-box message-image">
                                {message[0]}
                              </div>
                              <div className="message-text">
                                <div className="message-image" >
                                  <div className="bot-icon" style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <div className="text-left text-primary"><strong>Baap</strong></div> */}
                                    <img src={bot} alt="Bot Icon" className="navbar-icon img-fluid" style={{ width: '12%', height: '12%' }} />
                                    {/* <div className="text-right"><strong>GPT</strong></div> */}
                                  </div>
                                  <TypingEffect message={message[1]} isBot={true} typingSpeed={0} onCopy={handleCopy} />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="message-content user-message-box">
                              {/* ... (existing code) ... */}
                              <div className="message-text">
                                {message[1]}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div ref={messagesEndRef} />

                      {loading && (
                        <div className="d-flex justify-content-start">
                          <PulseLoader
                            className="ms-3"
                            color="#177bdb"
                            size={10}
                            speedMultiplier={2}
                            css={{ position: 'absolute', bottom: '10px', right: '10px' }}
                          />
                        </div>
                      )}
                    </div>
                    <Modal show={showModal2} onHide={() => setShowModal2(false)} dialogClassName="modal-lg">
                      <Modal.Header closeButton>
                        <Modal.Title>HugChat</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {{ selectedApiResponse2 } &&
                          (messages2.map((message, index) => (
                            <div key={index} className={`message ${message[1] ? 'bot' : 'user'}`}>
                              {message[1] ? (
                                <div className=" bot-message-box">
                                  <div className="message-box message-image width-300" >
                                    {message[0]}
                                  </div>
                                  <div className="message-text">
                                    <div className="message-image" >
                                      <div className="bot-icon" style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <div className="text-left text-primary"><strong>Baap</strong></div> */}
                                        <img src={bot} alt="Bot Icon" className="navbar-icon img-fluid" style={{ width: '12%', height: '12%' }} />
                                        {/* <div className="text-right"><strong>GPT</strong></div> */}
                                      </div>
                                      <SimpleMessage message={message[1]} isBot={true} typingSpeed={0} onCopy={handleCopy} />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="message-content user-message-box">
                                  {/* ... (existing code) ... */}
                                  <div className="message-text">
                                    {message[1]}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                          )
                        }

                      </Modal.Body>
                      {/* Other modal content */}
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3 ">
              <div className="card">
                <div className="card-body">
                  <i
                    className="fa-solid fa-expand position-absolute top-0 end-0 me-2 mt-2"
                    onClick={() => handleExpandClick(messages3, 3)}
                    style={{ cursor: "pointer" }}
                  ></i>
                  <h5 className="card-title">GPT API 3</h5>
                  <div className="chat-container">
                    <div id="chat-messages">
                      {messages3.map((message, index) => (
                        <div key={index} className={`message ${message[1] ? 'bot' : 'user'}`}>
                          {message[1] ? (
                            <div className=" bot-message-box">
                              <div className="message-content" >
                                <div className="user-icon" style={{ display: 'flex', alignItems: 'center' }}>
                                  <img src={"https://st4.depositphotos.com/20523356/22445/v/450/depositphotos_224458102-stock-illustration-flat-user-icon-face-avatar.jpg"} alt="User" style={{ width: '40px', height: '40px' }} />
                                  <div className="text-right">User</div>
                                </div>
                                <div className="message-box message-image">
                                  {message[0]}
                                </div>
                                <div className="message-text">
                                  <div className="message-image" >
                                    <div className="bot-icon" style={{ display: 'flex', alignItems: 'center' }}>
                                      {/* <div className="text-left text-primary"><strong>Baap</strong></div> */}
                                      <img src={bot} alt="Bot Icon" className="navbar-icon img-fluid" style={{ width: '12%', height: '12%' }} />
                                      {/* <div className="text-right"><strong>GPT</strong></div> */}

                                    </div>
                                    <TypingEffect message={message[1]} isBot={true} typingSpeed={0} onCopy={handleCopy} />
                                    {/* <pre class="bot-code">{ message[1] }</pre> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="message-content user-message-box">
                              <div className="message-image">
                                {/* Add your user image here */}
                                <img src={process.env.PUBLIC_URL + "/path/to/user-image.png"} alt="User" style={{ width: '20px', height: '20px' }} />
                              </div>
                              <div className="message-text">
                                {message[1]}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div ref={messagesEndRef} />

                      {loading && (
                        <div className="d-flex justify-content-start">
                          <PulseLoader
                            className="ms-3"
                            color="#177bdb"
                            size={10}
                            speedMultiplier={2}
                            css={{ position: 'absolute', bottom: '10px', right: '10px' }}
                          />
                        </div>
                      )}
                    </div>
                    <Modal show={showModal3} onHide={() => setShowModal3(false)} dialogClassName="modal-lg">
                      <Modal.Header closeButton>
                        <Modal.Title>GPT API 3</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {{ selectedApiResponse3 } &&
                          (messages3.map((message, index) => (
                            <div key={index} className={`message ${message[1] ? 'bot' : 'user'}`}>
                              {message[1] ? (
                                <div className=" bot-message-box">
                                  <div className="message-box message-image width-300" >
                                    {message[0]}
                                  </div>
                                  <div className="message-text">
                                    <div className="message-image" >
                                      <div className="bot-icon" style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <div className="text-left text-primary"><strong>Baap</strong></div> */}
                                        <img src={bot} alt="Bot Icon" className="navbar-icon img-fluid" style={{ width: '12%', height: '12%' }} />
                                        {/* <div className="text-right"><strong>GPT</strong></div> */}
                                      </div>
                                      <SimpleMessage message={message[1]} isBot={true} typingSpeed={0} onCopy={handleCopy} />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="message-content user-message-box">
                                  {/* ... (existing code) ... */}
                                  <div className="message-text">
                                    {message[1]}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                          )
                        }

                      </Modal.Body>
                      {/* Other modal content */}
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12  d-flex justify-content-center" style={{ marginTop: '2.8em' }}>
            <form
              onSubmit={(e) => handleSubmit(e, "api1_chat", "api2_chat", "api3_chat")}
              className="mb-3 d-flex align-items-center border border-secondary Input-box"
            >
              <div className="input-group">
                <label htmlFor="fileInput">
                  <div>
                    <BiImageAdd
                      className="InputSvg mt-2"
                      style={{ cursor: 'pointer', marginRight: '6px' }}
                      size={24} // Adjust the size as needed
                    // onChange={handleImageChange} 
                    />
                    {selectedImage && (
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Selected Image"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    )}
                    {/* <input type="file" onChange={handleImageChange} /> */}
                    {/* {selectedImage && <img src={URL.createObjectURL(selectedImage)} alt="Selected Image" />} */}
                    {/* ... other chat elements */}
                  </div>

                </label>
                {/* Actual file input, but hidden */}
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: 'none' }}
                  // onChange={handleImageClick}
                  onChange={handleImageChange}
                />
                <textarea
                  className="form-control form-control-lg input-bar auto-expand"
                  placeholder="Message BaapGPT..."
                  aria-label=".form-control-lg example"
                  rows="1"
                  value={userInput}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleImageChange(e);
                      handleSubmit(e, "api1_chat", "api2_chat", "api3_chat");
                    }
                  }}
                  maxLength={10000}
                />
              </div>
              <img
                src={InputSvg}
                className={`InputSvg ${loading ? 'disabled' : ''}`}
                onClick={() => handleImageClick("api1_chat", "api2_chat", "api3_chat")}
                alt="Send Icon"
              />
            </form>
          </div>
        </div>
      </div >
      <div ref={messagesEndRef} />
    </ >
  );
};

export default Dashboard;
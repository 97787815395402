import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Home from "./components/home.component";
import Login from "./components/login.component";
import SignUp from "./components/signup.component";
import Dashboard from "./components/dashboard.component";
import ForgotPass from "./components/ForgotPassword.component";
import PasswordResetPage from "./components/PasswordResetPage.component";
import AuthService from "./components/authService";


const PrivateRoute = ({ element }) => {
  return AuthService.isAuthenticated ? (
      element
  ) : (
      <Navigate to="/sign-in" replace />
  );
};

function App() {
  useEffect(() => {
    // Initialize authentication state
    AuthService.initializeAuth();
  }, []);
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-in" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />}/>
          <Route path="/forgotPass" element={<ForgotPass />} />
          <Route path="/newPass/:uidb64/:token" element={<PasswordResetPage />}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import './Sidebar.css';
import { Link } from "react-router-dom";
import { FaUser, FaTasks, FaLock, FaLayerGroup, FaBell, FaCog, FaSignInAlt } from 'react-icons/fa';


const Sidebar = () => {
  const handleToggle = () => {
    document.querySelector("#sidebar").classList.toggle("expand");
  };

  return (
    <aside id="sidebar" className="bg-gradient bg-dark d-flex flex-column justify-content-start align-items-center" style={{ height: '91.5vh' }}>

        <button className="toggle-btn border-0 " type="button"  onClick={handleToggle}>
          <span style={{ fontSize: '30px', cursor: 'pointer', }}>&#9776;</span>
        </button>
        <div className="text-light mt-2 mb-auto">History</div>
    </aside>


  );
};

export default Sidebar;

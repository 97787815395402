import axios from 'axios';

const AuthService = {
  isAuthenticated: false,

  initializeAuth: async () => {
    try {
      // Make an API call to check the current authentication state
      const response = await axios.get('http://3.110.29.90:8002/check_authentication/', { timeout: 100000 });

      
      if (response.data.isAuthenticated) {
        AuthService.isAuthenticated = true;
        localStorage.setItem('authenticated', 'true');
      } else {
        AuthService.isAuthenticated = false;
        localStorage.setItem('authenticated', 'false');
      }
    } catch (error) {
      console.error('Authentication initialization error:', error);
    }
  },

    login: async (email, password) => {
        try {
            const response = await axios.post('http://3.110.29.90:8002/Login_view/', {
                email: email,
                password: password,
            }, { timeout: 100000 });

            if (response.data.message === 'Login successful') {
                AuthService.isAuthenticated = true;
                localStorage.setItem('authenticated', 'true');
            } else {
                console.error('Login Error:', response.data.error_message);
            }
        } catch (error) {
            console.error('Login Error:', error);
        }
    },

    logout: async () => {
        try {
            await axios.post('http://3.110.29.90:8002/logout/', { timeout: 100000 });
            AuthService.isAuthenticated = false;
            localStorage.setItem('authenticated', 'false');
        } catch (error) {
            console.error('Logout Error:', error);
        }
    },
};

export default AuthService;

// Navbar.js

import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ authenticated, setAuthenticated }) => {
  const handleLogout = async () => {
    try {
      // Call the logout API or your backend endpoint for logout
      // You may use axios or any other HTTP client library for this
      // Example using fetch:
      const response = await fetch('http://3.110.29.90:8002/Logout_view/', {
        method: 'POST',
        credentials: 'include', // Include credentials for cross-origin requests
      });

      if (response.ok) {
        console.log('Logout Successful');
        // Set authentication status to false
        setAuthenticated(false);
        // Redirect to the home page after logout
        window.location.href = '/';
      } else {
        console.error('Logout Error:', response.statusText);
        // Handle logout error as needed
      }
    } catch (error) {
      console.error('Logout Error:', error.message);
      // Handle logout error as needed
    }
  };

  return (
    <nav className="navbar navbar-expand navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to="/">
          The Data Tech Labs
        </Link>

        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ml-auto">
            {authenticated ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/sign-in">
                    Sign In
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sign-up">
                    Sign Up
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
